import {
  CloseOutlined,
  RightOutlined,
  CheckOutlined,
  LoadingOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import {
  useBoolean,
  useLocalStorageState,
  useLockFn,
  useMount,
  usePrevious,
  useThrottleEffect,
} from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import useUrlState from "@ahooksjs/use-url-state";
import dayjs from "dayjs";
import useActiveState from "../general/useActiveState";
import { useHistory } from "react-router-dom";
import general from "../../general";
import URI from "urijs";
import useReactive from "../general/useReactive";
import _ from "lodash";

const PayDialog = ({ show = true, close = null, children, className }) => {
  return (
    <CSSTransition in={show} timeout={250} appear={true} unmountOnExit={true}>
      <div className={`pay-dialog ${className}`}>
        <div className="bg" onClick={() => close && close()}></div>
        <div className="container">{children}</div>
      </div>
    </CSSTransition>
  );
};

const PaySuccess = ({ ad = {}, order = {} }) => {
  useMount(() => {
    window.location.href = URI("sdk://pay-state")
      .setQuery("orderId", order.orderId)
      .setQuery("amount", order.amount)
      .setQuery("state", 1)
      .toString();
  });
  return (
    <PayDialog className="pay-success">
      <div className="body">
        <CheckOutlined className="icon" />
        <div className="title">充值成功</div>
        <div className="ad">
          <a
            href={URI("sdk://browser")
              .addQuery("url", ad.advertisement_link)
              .toString()}
          >
            <img src={ad.advertisement_img} />
          </a>
        </div>
      </div>
      <a className="close" href="sdk://close">
        关闭
      </a>
    </PayDialog>
  );
};

export default () => {
  const history = useHistory();
  const [params] = useUrlState();
  const [user] = useActiveState("user");
  const [vouchers, setVouchers] = useState([]);
  const [voucher, setVoucher] = useState();
  const [voucherAuto, setVoucherAuto] = useState();
  const [voucherBest, setVoucherBest] = useState();
  const getVoucherBest = (vouchers) =>
    vouchers.length === 0
      ? null
      : vouchers.sort((a, b) => b.amount - a.amount)[0];
  const voucherPrev = usePrevious(voucher, (prev, next) => prev != next);
  const [
    voucherSelect,
    { setTrue: voucherSelectTrue, setFalse: voucherSelectFalse },
  ] = useBoolean();
  const [methods, setMethods] = useLocalStorageState("paytypes", []);
  const [method, setMethod] = useLocalStorageState("paytype");
  const methodPrev = usePrevious(method, (prev, next) => prev != next);
  const [
    methodSelect,
    { setTrue: methodSelectTrue, setFalse: methodSelectFalse },
  ] = useBoolean();
  const data = useReactive({
    amount: params.amount * 1 || 0,
    rechargeDiscount: 1,
    payAmount: 1,
    deductAmount: undefined,
    ptb: undefined,
    flb: undefined,
    loaded: undefined,
    orderId: undefined,
    subsidy: undefined, // 补贴
    subsidyRatio: undefined,
    subsidyAmount: undefined,
  });

  useMount(() => {
    Dialog.loading();
    axios
      .get(`/pay/getPayInformation?amount=${data.amount}`)
      .finally(() => Dialog.close())
      .then(({ data: resData }) => {
        if (resData.code != 0) {
          Dialog.error(resData.msg);
          return;
        }
        resData = resData.data;
        data.ptb = resData.userPtbNum * 1 || 0;
        data.flb = resData.flb * 1 || 0;
        data.rechargeDiscount = resData.recharge_discount * 1 || 1;
        data.subsidyRatio = resData.subsidy_ratio
          ? parseInt(resData.subsidy_ratio)
          : undefined;
        if (data.subsidyRatio) {
          data.subsidy = true;
        }
        let vouchers = resData.voucherList.map((item) => ({
          ...item,
          amount: item.amount * 1,
          deductAmount: item.amount > data.amount ? data.amount : item.amount,
        }));
        if (vouchers.length > 0) {
          let voucher = getVoucherBest(vouchers);
          setVoucher(voucher);
          setVoucherAuto(true);
          setVoucherBest(voucher);
        }
        setVouchers(vouchers);
        setMethods([
          ...resData.payType.map((item) => ({
            ...item,
            sort: ["ptb", "flb"].includes(item.type) ? 1 : 0,
          })),
          /*{id: 'alipay', type: 'alipay-scheme', name: '支付宝支付 scheme', pay_source_type: 2},
          {id: 'alipay', type: 'alipay-h5', name: '支付宝支付 h5', pay_source_type: 3},
          {id: 'wxpay', type: 'wxpay-scheme', name: '微信支付 scheme', pay_source_type: 2},*/
        ]);
        data.loaded = true;
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useThrottleEffect(
    () => {
      let ts = methods.map((item) => {
        let t = { ...item };
        if (t.type == method?.type) {
          t.sort += 2;
        } else if (t.type == methodPrev?.type) {
          t.sort += 1;
        }
        return t;
      });
      ts.sort((a, b) => b.sort - a.sort);
      let t = ts.find((method) => !method.disable && method.enough);
      if (method?.type != t?.type) {
        setMethod(t);
      }
    },
    [methods, method, methodPrev],
    { wait: 100 }
  );

  function moneyCalc(num) {
    let unitPrem = 0;
    let b = num.toString().split(".")[1];
    let a = num.toString().split(".")[0];
    if (b) {
      if (b && b.length >= 3) {
        b = b.substring(0, 3);
        let c = b + "1";
        unitPrem = Number(a + "." + c);
      } else {
        unitPrem = num;
      }
    } else {
      unitPrem = num;
    }
    unitPrem = unitPrem.toFixed(2) * 1;

    return unitPrem;
  }

  useEffect(() => {
    setMethods((methods) =>
      methods.map((item) => ({
        ...item,
        disable: (data.subsidy && ["ptb", "flb"].includes(item.type)) || false,
        enough: ["ptb", "flb"].includes(item.type)
          ? ["ptb", "flb"].includes(item.type) &&
            data.payAmount <= data[item.type] / 10
          : true,
        amount: ["ptb", "flb"].includes(item.type) ? data[item.type] : null,
      }))
    );
  }, [data.payAmount, data.loaded, data.subsidy]);
  useThrottleEffect(
    () => {
      data.payAmount = data.amount;
      data.deductAmount = voucher?.deductAmount || 0;
      data.payAmount = data.payAmount - data.deductAmount;
      if (data.rechargeDiscount < 1) {
        data.payAmount = (data.payAmount * (data.rechargeDiscount * 10)) / 10;
      }
      if (data.subsidy) {
        data.subsidyAmount = (data.payAmount * data.subsidyRatio) / 100;
        data.payAmount = data.payAmount - data.subsidyAmount;
      }
      if (voucherPrev) {
        setVoucherAuto(false);
      }

      data.payAmount = _.round(data.payAmount, 2);
    },
    [data.amount, data.rechargeDiscount, voucher, voucherPrev, data.subsidy],
    { wait: 100 }
  );

  useEffect(() => {
    if (data.subsidy) {
      setVoucher(undefined);
    }
  }, [voucher, data.subsidy]);

  const [submitting, setSubmitting] = useState(false);
  const [paying, setPaying] = useState(false);
  const [payUrl, setPayUrl] = useState();
  const [querying, setQuerying] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ad, setAd] = useState();
  const pay = useLockFn(async () => {
    setPaying(false);
    setSubmitting(true);
    Dialog.loading();
    await axios
      .post("/pay/placeOrder", {
        roleid: params.roleId,
        serverid: params.serverId,
        productname: params.productName,
        productdesc: params.productDesc,
        amount: params.amount,
        attach: params.orderId,
        pay_type: method.id || method.type,
        pay_source_type: method.type.startsWith("wxpay")
          ? 2
          : method.pay_source_type,
        is_coupon: !!voucher,
        coupon_id: voucher?.id || undefined,
        timestamp: params.timestamp,
        noncestr: params.noncestr,
        sign: params.sign,
        is_subsidy: data.subsidy ? 1 : 0,
      })
      .finally(() => {
        Dialog.close();
        setSubmitting(false);
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setPaying(true);
        let { data: resData } = res.data;
        data.orderId = resData.order_id;
        if (resData.pay_status == 1) {
          setPaying(false);
          setSuccess(true);
          return;
        }
        if (resData.wxPayUrl) {
          window.location.href = resData.wxPayUrl;
        } else if (resData.wxPayParams) {
          window.location.href = window.location.href = URI("sdk://wxpay")
            .setQuery(
              "parms",
              URI("").query(resData.wxPayParams).toString().substring(1)
            )
            .toString();
        } else {
          let res = resData.zfbPayUrl || resData.zfbPayHtml;
          if (res.indexOf("://") != -1) {
            if (["youshangyun", "suqiyun"].includes(params.source)) {
              console.log({ source: params.source });
              console.log({ res });
              window.location.href = res;
            } else {
              setPayUrl(res);
            }
          } else {
            window.location.href = URI("sdk://alipay")
              .addQuery("parms", res)
              .toString();
          }
        }
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useEffect(() => {
    const query = async () => {
      if (!paying || querying) {
        return;
      }
      setQuerying(true);
      let res;
      try {
        res = await axios.get(`/pay/payStatus?order_id=${data.orderId}`);
        console.info({ res });
      } catch (e) {
        console.error(e);
      }

      try {
        window.webkit.messageHandlers["logger"].postMessage("111");
      } catch (e) {}

      if (res?.data?.code == 0) {
        setPaying(false);
        setQuerying(false);
        setSuccess(true);
        whetherPay("success", data.payAmount);
        setAd(res.data.data);
      } else {
        whetherPay("faild", data.payAmount);
        setTimeout(() => setQuerying(false), 1527);
      }
    };
    query();
  }, [paying, querying]);

  useEffect(() => {
    window.pullupapp = (e) => {
      window.location.href = "sdk://close";
      /*if (params.username == 'apkceshi' || params.username == 'iosceshi') {
        return;
      }*/
      /*console.log(e);
      Dialog.alert('pullupapp', `pullupapp ${e?.app}`);
      if (e?.app && /(alipay|wxpay)/.test(e.app) && voucher) {
        window.location.href = 'sdk://close';
      }*/
    };
    /*window.paysuccess = e => {
      console.log(e);
      Dialog.alert('paysuccess', `paysuccess ${e?.paytype}`);
    }
    window.cancelpay = e => {
      console.log(e);
      Dialog.alert('cancelpay', `cancelpay ${e?.paytype}`);
    }*/
  }, [voucher, params]);

  const hasApp = (packname) => {
    try {
      if (window.aoyou.hasApp(packname)) {
        Dialog.alert("hasApp", `有 ${packname}`);
      } else {
        Dialog.alert("hasApp", `没有 ${packname}`);
      }
    } catch (e) {
      Dialog.alert("error", e.message);
    }
  };

  const [scheme, setScheme] = useState();

  const hasAppIos = (scheme) => {
    setScheme(scheme);
    try {
      window.webkit.messageHandlers.hasApp.postMessage({
        scheme: scheme,
        func: "checkAPPIsExist",
      });
    } catch (e) {
      Dialog.alert("error", e.message);
    }
  };

  const whetherPay = (state, amount) => {
    let thod = method.id || method.type;
    let payThod = "";
    if (["wxpay", "alipay", "ptb"].indexOf(thod) === -1) {
      payThod = "other";
    } else {
      payThod = thod === "wxpay" ? "wechat" : thod;
    }

    if (window.aoyou?.putPayState(payThod, state, amount.toString())) {
      console.log("给安卓回传支付方式及结果");
    } else {
      console.log("没有回传支付方式及结果方法存在");
    }
  };

  useEffect(() => {
    window.checkAPPIsExist = (e) => {
      console.log(e);
      Dialog.alert("hasApp", `${scheme} ${e ? "有" : "没有"}`);
    };
  }, [scheme]);

  return (
    <div className="pay-pay">
      <div className="pay-head">
        {/*<div className="test" onClick={() => {
        Dialog.alert('事件测试', <div style={{padding: 5}}>
          <a href="box9917://">拉起9917盒子</a>
          <button onClick={() => window.location.href = 'box9917://voucher'} style={{margin: 5}}>拉起9917盒子</button>
          <button onClick={() => hasApp('com.aoyou.gamebox')} style={{margin: 5}}>安卓是否安装9917盒子</button>
          <button onClick={() => hasApp('box9917://')} style={{margin: 5}}>安卓是否安装9917盒子（scheme）</button>
          <button onClick={() => hasApp('com.maiyou.gamebox')} style={{margin: 5}}>安卓是否安装麦游盒子</button>
          <button onClick={() => hasAppIos('box9917://')} style={{margin: 5}}>iOS是否安装9917盒子</button>
          <button onClick={() => hasAppIos('alipay://')} style={{margin: 5}}>iOS是否安装支付宝</button>
          <button onClick={() => window.pullupapp({app: rando(['box9917', 'qq', 'alipay', 'wxpay']).value})} style={{margin: 5}}>拉起事件</button>
          <button onClick={() => window.paysuccess({paytype: rando(['wxpay', 'alipay']).value})} style={{margin: 5}}>支付成功事件</button>
          <button onClick={() => window.cancelpay({paytype: rando(['wxpay', 'alipay']).value})} style={{margin: 5}}>取消支付事件</button>
        </div>);
      }}>测试
      </div>*/}
        {payUrl ? (
          <LeftOutlined className="back" onClick={() => setPayUrl(undefined)} />
        ) : null}
        <div className="title">充值中心</div>
        <CloseOutlined
          className="close"
          onClick={() =>
            Dialog.confirm({
              title: "确定关闭吗？",
              yes: () => {
                window.location.href = URI("sdk://pay-state")
                  .setQuery("orderId", params.orderId)
                  .setQuery("amount", params.amount)
                  .setQuery("state", 0)
                  .toString();
                general.native
                  ? (window.location.href = "sdk://close")
                  : history.push("/");
              },
            })
          }
        />
      </div>
      <div className="pay-body">
        <div className="pay-main">
          <div className="pay-info">
            <div className="item">您当前使用的账号是：{user.username}</div>
            {methods.filter((data) => ["ptb", "flb"].includes(data.type))
              .length && (
              <>
                <div className="item">
                  您当前拥有
                  {methods.map((data) => data.type).includes("ptb") && (
                    <>
                      <span>{data.ptb}</span> 个平台币{" "}
                    </>
                  )}
                  {methods.map((data) => data.type).includes("flb") && (
                    <>
                      <span>{data.flb}</span>个福利币{" "}
                    </>
                  )}
                </div>
              </>
            )}
            {voucherAuto ? (
              <div className="item voucher-autoed">已为您选择最佳优惠组合</div>
            ) : null}
          </div>
          <div className="pay-data">
            <div className="pay-item">
              <div className="name">订单金额：</div>
              <div className="value order">¥ {data.amount}</div>
            </div>
            <div className={`pay-item ${data.subsidy ? "disable" : ""}`}>
              <div className="name">代金券：</div>
              <div
                className="value voucher"
                onClick={!data.subsidy ? voucherSelectTrue : undefined}
              >
                <div className="text">
                  {voucher ? (
                    `-${voucher.deductAmount}`
                  ) : (
                    <div>{vouchers.length}张可以用</div>
                  )}
                </div>
                <RightOutlined className="more" />
              </div>
            </div>
            {data.subsidyRatio ? (
              <>
                <div
                  className={`pay-item ${data.subsidy ? "" : "disable"}`}
                  onClick={() => (data.subsidy = !data.subsidy)}
                >
                  <div className="name">百亿补贴：</div>
                  <div className="value subsidy">
                    <div
                      className={`switch  ${data.subsidy ? "active" : ""}`}
                    />
                    <div className="value">-¥{data.subsidyAmount}</div>
                    <div className="value">优惠{data.subsidyRatio}%</div>
                    <QuestionCircleOutlined
                      className="tips"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        Dialog.alert(
                          "百亿补贴",
                          "百亿补贴活动具有唯一性，该笔订单不能使用平台币、代金券支付"
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}
            {data.rechargeDiscount < 1 ? (
              <div className="pay-item">
                <div className="name">折扣：</div>
                <div className="value order">
                  {parseFloat((data.rechargeDiscount * 10).toFixed(2))} 折
                </div>
              </div>
            ) : null}
            <div className="pay-item">
              <div className="name">支付方式：</div>
              <div className="value method" onClick={methodSelectTrue}>
                <div className="all">
                  {methods.map((item) =>
                    !item.disable &&
                    item.type != method?.type &&
                    !item.pay_source_type ? (
                      <img
                        src={`/static/img/${item.id || item.type}-icon.png`}
                        className="item"
                      />
                    ) : null
                  )}
                </div>
                {method ? (
                  <div className="current">
                    <img
                      src={`/static/img/${method.id || method.type}-icon.png`}
                      alt=""
                    />
                    <div className="text">{method.name}</div>
                  </div>
                ) : null}
                <RightOutlined className="more" />
              </div>
            </div>
            <div className="pay-item">
              <div className="name">您需要支付：</div>
              <div className="value pay">
                ¥{data.payAmount}（已抵扣-¥{data.deductAmount}）
              </div>
            </div>
          </div>
        </div>
        <div className={`operate ${submitting ? "ing" : ""}`} onClick={pay}>
          <LoadingOutlined className="icon" />
          <div className="text">充值</div>
        </div>
      </div>
      {payUrl ? (
        <div className="pay-iframe">
          <iframe src={payUrl} />
        </div>
      ) : null}
      <PayDialog
        show={voucherSelect}
        close={voucherSelectFalse}
        className="pay-voucher-select"
      >
        <div className="head">
          <div className="title">请选择代金券</div>
          <CloseOutlined className="close" onClick={voucherSelectFalse} />
        </div>
        <div className="body">
          {voucher && voucher == voucherBest ? (
            <div className="tips">
              <div className="text">
                已选择最优组合，当前组合已抵扣 -¥{voucher?.deductAmount}
              </div>
            </div>
          ) : null}
          <div className="operate">
            <div className="name">不使用代金券</div>
            <div className="value">
              <div
                className={`item ${voucher ? "active" : ""}`}
                onClick={() =>
                  setVoucher(voucher ? null : getVoucherBest(vouchers))
                }
              >
                <CheckOutlined className="icon" />
              </div>
            </div>
          </div>
          <div className="items">
            {vouchers.map((item) => (
              <div
                className={`item ${item == voucher ? "active" : ""}`}
                onClick={() => {
                  if (method.type == "flb" && item.origin_type == "platform") {
                    Dialog.error("平台代金券和福利币不能组合使用");
                    return;
                  }

                  setVoucher(item);
                  voucherSelectFalse();
                }}
              >
                <div className="amount">
                  <div className="value">
                    <div className="unit">￥</div>
                    <div className="number">{item.amount * 1}</div>
                  </div>
                  <div className="desc">
                    {item.meet_amount > 0
                      ? `满${item.meet_amount * 1}使用`
                      : "任意金额使用"}
                  </div>
                </div>
                <div className="info">
                  <div className="name">
                    <div className="type">单游券</div>
                    <div className="named">{item.name}</div>
                  </div>
                  <div className="desc">{item.end_time} 到期</div>
                </div>
                <div className="active">
                  <CheckOutlined className="icon" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </PayDialog>
      <PayDialog
        show={methodSelect}
        close={methodSelectFalse}
        className="pay-method-select"
      >
        <div className="title">请选择充值方式</div>
        <div className="items">
          {methods.map((item) =>
            item.disable ? null : (
              <div
                className={`item ${item.type == method?.type ? "active" : ""} ${
                  item.disable ? "disable" : ""
                } ${!item.enough ? "notenough" : ""}`}
                onClick={() => {
                  if (item.disable || !item.enough) {
                    return;
                  }

                  if (
                    item.type == "flb" &&
                    voucher &&
                    voucher.origin_type == "platform"
                  ) {
                    Dialog.error("平台代金券和福利币不能组合使用");
                    return;
                  }
                  setMethod(item);
                  methodSelectFalse();
                }}
              >
                <div className="icon">
                  <img
                    src={`/static/img/${item.id || item.type}-icon.png`}
                    alt=""
                  />
                </div>
                <div className="name">
                  <div className="named">{item.name}</div>
                  <div className="tips">
                    {!item.enough ? `余额不足 ${item.amount}` : ""}
                  </div>
                </div>
                <div className="state">
                  <CheckOutlined className="icon" />
                </div>
              </div>
            )
          )}
        </div>
      </PayDialog>
      {success ? (
        <PaySuccess
          ad={ad}
          order={{ orderId: params.orderId, amount: params.amount }}
        />
      ) : null}
    </div>
  );
};
